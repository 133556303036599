<template>
  <div>
    <h3>Сертификаты:</h3> <br>
    <ul v-if="Object.keys(certificates).length > 0">
      <li
          v-for="certificate in certificates"
          :key="certificate.uuid"
      >
        <a
            href="javascript:void(0)"
            @click="makeCertificate(certificate)"
        >
          {{ certificate.test.title }}
        </a>
        <span class="certificate-date"> выдан:
          {{ String(new Date(Date.parse(certificate.createdAt)).getDate()).padStart(2, "0") }}.
          {{ String(new Date(Date.parse(certificate.createdAt)).getMonth()).padStart(2, "0") }}.
          {{ new Date(Date.parse(certificate.createdAt)).getFullYear() }}
        </span>
      </li>
    </ul>
    <h3
        v-else
        class="certificate-date">Сертификатов пока нет</h3>
  </div>
</template>

<script>
import { jsPDF } from 'jspdf';
import dateUnderline from '../assets/certificate/line.png';
import certificateLogo from '../assets/certificate/logo.png';
import certificateStamp from '../assets/certificate/stamp.png';
import { getCertificates } from '../api/profi_club/certificates';
import usernameBackdrop from '../assets/certificate/UsernameBackdrop.png';
import certificateBackground from '../assets/certificate/testCertificateBackground.png';

export default {
  name: 'Certificates',
  props: ['profile'],
  data: () => ({
    certificates: {},
  }),
  methods: {
    async makeCertificate(certificate) {
      const colorPrimary = '#fff';
      const date = new Date(Date.parse(certificate.createdAt));
      const dateString = `${String(date.getDate()).padStart(2, '0')}`
        + `.${String(date.getMonth() + 1).padStart(2, '0')}`
        + `.${String(date.getFullYear())}`;

      try {
        /* eslint-disable new-cap */
        const testCertificate = new jsPDF();

        // Подключаем шрифты
        testCertificate.addFont('fonts/Roboto-Italic.ttf', 'Roboto', 'normal-italic');
        testCertificate.addFont('fonts/Roboto-MediumItalic.ttf', 'Roboto', 'medium-italic');
        testCertificate.addFont('fonts/Roboto-Bold.ttf', 'Roboto', 'bold');

        // Фоновое изображение
        testCertificate.addImage(certificateBackground, 'JPEG', 0, 0, 210, 297, null, 'FAST');
        // Логотип
        testCertificate.addImage(certificateLogo, 'PNG', 135, 13.8, 60.07, 12.72, null, 'NONE');

        // Заголовок
        testCertificate.setTextColor(colorPrimary);
        testCertificate.setFont('Roboto', 'bold');
        testCertificate.setFontSize(50);
        testCertificate.text('СЕРТИФИКАТ', 105, 55, { align: 'center' });

        // Текст
        testCertificate.setFont('Roboto', 'bold');
        testCertificate.setFontSize(18);
        testCertificate.text('свидетельствует о том, что профессионал', 105, 65, { align: 'center' });
        testCertificate.addImage(usernameBackdrop, 'PNG', 0, 75, 210, 40);
        testCertificate.setTextColor(colorPrimary);
        testCertificate.setFontSize(30);
        testCertificate.text(
          [`${this.profile.firstName} ${this.profile.lastName}`],
          105,
          99,
          { align: 'center', maxWidth: 180 },
        );
        testCertificate.setFontSize(18);
        testCertificate.text(['Успешно прошел тестирование', 'на тему:'], 105, 125, { align: 'center' });
        // Наименование устройства
        testCertificate.setTextColor(colorPrimary);
        testCertificate.setFont('Roboto', 'bold');
        testCertificate.setFontSize(50);
        testCertificate.text([`"${certificate.test.title}"`],
          105,
          155,
          { align: 'center', maxWidth: 180 });
        // Подпись под печатью
        testCertificate.setFontSize(15);
        testCertificate.setFont('Roboto', 'bold');
        testCertificate.text(`${dateString}`, 105, 240, { align: 'center' });
        testCertificate.addImage(dateUnderline, 'PNG', 75, 243, 70, 0.5);
        // Печать
        testCertificate.addImage(certificateStamp, 'PNG', 137, 205, 58.16, 58.16, null, 'NONE');

        // Открытие сертификата в новом окне
        window.open(URL.createObjectURL(testCertificate.output('blob')));
      } catch (e) {
        this.$bvToast.toast(
          `Не получилось отобразить сертификат: ${certificate.title}`,
          {
            title: 'Ошибка',
            variant: 'danger',
            autoHideDelay: 10000,
          },
        );
      }
    },
  },
  computed: {},
  async mounted() {
    const resp = await getCertificates();
    this.certificates = resp?.list ?? {};
  },
};
</script>

<style lang="scss">
.certificate-date {
  margin-left: 5px;
  font-size: 12px;
  color: darkgrey;
}
</style>
