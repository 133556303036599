<template>
  <b-container class="profile-view">
    <PageHeader class="mb-4">Профиль</PageHeader>

    <b-overlay
      :show="isLoading"
      rounded="sm"
      variant="white"
      opacity="0.85"
      spinner-variant="primary"
    >
      <div class="d-flex flex-row justify-content-between flex-wrap">
        <div class="settings-column">
          <!-- Поле номер телефона -->
          <div
            class="mb-5"
          >
            <b-form-group
              label="Телефон"
              :disabled="true"
            >
              <b-form-input
                v-mask="'+7 ### ### ## ##'"
                :value="profile && profile.phoneNumber ? profile.phoneNumber : null"
              />
            </b-form-group>

            <b-button
              variant="outline-primary"
              to="/change-phone-number"
            >
              Изменить телефон
            </b-button>
          </div>
          <!-- /Поле номер телефона -->

          <!-- Поле email -->
          <div
              class="mb-5"
          >
            <b-form-group
              label="Email"
              :disabled="true"
            >
              <b-form-input
                :value="profile && profile.email ? profile.email : null"
              />
            </b-form-group>

            <b-button
              variant="outline-primary"
              to="/change-email"
            >
              Изменить Email
            </b-button>
          </div>
          <!-- /Поле email -->
        </div>

        <div class="settings-column">
          <!-- TODO: Вынести в отдельный компонент -->
          <b-form
              @submit="onSubmitForm"
          >
            <!-- Поле Имя -->
            <p class="mb-2">
              Имя <span class="text-danger font-weight-bold">*</span>
            </p>
            <b-form-group
              label-for="input-firstName"
              :invalid-feedback="form.firstName.invalidFeedback"
              :state="form.firstName.state"
            >
              <b-form-input
                  id="input-firstName"
                  v-model="form.firstName.value"
                  :state="form.firstName.state"
                  trim
              />
            </b-form-group>
            <!-- /Поле Имя -->

            <!-- Поле Фамилия -->
            <p class="mb-2">
              Фамилия <span class="text-danger font-weight-bold">*</span>
            </p>
            <b-form-group
              label-for="input-lastName"
              :invalid-feedback="form.lastName.invalidFeedback"
              :state="form.lastName.state"
            >
              <b-form-input
                id="input-lastName"
                v-model="form.lastName.value"
                :state="form.lastName.state"
                trim
              />
            </b-form-group>
            <!-- /Поле Фамилия -->

            <!-- Поле Страна -->
            <!-- TODO: подсказки -->
            <b-form-group
              label="Страна"
              label-for="input-country"
              :invalid-feedback="form.country.invalidFeedback"
              :state="form.country.state"
            >
              <b-form-input
                id="input-country"
                v-model="form.country.value"
                :state="form.country.state"
                trim
              />
            </b-form-group>
            <!-- /Поле Страна -->

            <!-- Поле Город -->
            <InputSuggestDropdown
              label="Город"
              label-for="input-city"
              id="input-city"
              inputPlaceholder="Начните вводить город (min 4 символа)"
              v-model="form.city.value"
              :state="form.city.state"
              :invalid-feedback="form.city.invalidFeedback"
              :isPending="!listCityIsLoading"
              :suggests="listCity"
              returnObjKey="value"
              @input="getCitiesList"
              v-slot="slotData"
            >
              {{ slotData.suggest.value }}<br />
            </InputSuggestDropdown>
            <!-- /Поле Город -->

            <!-- Ошибка сервера -->
            <b-alert
              show
              v-if="serverErrorMessage"
              variant="danger"
            >
              {{ serverErrorMessage }}
            </b-alert>
            <!-- /Ошибка сервера -->

            <b-button
              type="submit"
              variant="primary"
              block
              :disabled="isFormButtonDisabled"
            >
              Сохранить изменения
            </b-button>

            <!-- Обязательные поля -->
            <p class="mt-2">
              <span class="text-danger font-weight-bold">*</span> - обязательные поля
            </p>
            <!-- /Обязательные поля -->
            <b-link
                @click="logout();"
                class="d-flex justify-content-center"
            >
              <b-icon
                  icon="door-open-fill"
                  class="mt-1"
              />
              Выход
            </b-link>
          </b-form>
        </div>
      </div>
      <Certificates :profile="this.profile"/>

    </b-overlay>

  </b-container>
</template>

<script>
import { mask } from 'vue-the-mask';
import { mapActions } from 'vuex';
import jsonwebtoken from 'jsonwebtoken';
import { config } from '../config';
import PageHeader from '../components/PageHeader.vue';
import InputSuggestDropdown from '../components/InputSuggestDropdown.vue';
import {
  getPersonalData,
  changePersonalData,
} from '../api/esia/personal_data';
import { getErrorTextFromErResp } from '../api/utils';
import Certificates from '../components/Certificates.vue';
import { getListCity } from '../utils/index';

export default {
  name: 'Profile',

  directives: {
    mask,
  },

  components: {
    PageHeader,
    InputSuggestDropdown,
    Certificates,
  },
  computed: {
    isFormButtonDisabled() {
      return !this.form.firstName.value || this.form.firstName.value.length < 1
          || !this.form.lastName.value || this.form.lastName.value.length < 1;
    },
  },

  data() {
    return {
      isLoading: true,
      serverErrorMessage: null,
      form: {
        firstName: {
          value: null,
          state: null,
          invalidFeedback: null,
        },
        lastName: {
          value: null,
          state: null,
          invalidFeedback: null,
        },
        country: {
          value: null,
          state: null,
          invalidFeedback: null,
        },
        city: {
          value: null,
          state: null,
          invalidFeedback: null,
        },
        isProfessionalUser: {
          value: null,
          state: null,
          invalidFeedback: null,
        },
      },

      // Города (подсказки)
      listCityIsLoading: false,
      listCity: [],

      // Данные из JWT
      profile: null,
    };
  },

  methods: {
    ...mapActions('auth', {
      signOut: 'signOut',
    }),
    logout() {
      this.signOut()
        .then(() => {
          this.$router.push({ name: 'login' });
        })
        .catch(() => {
          this.$router.push({ name: 'login' });
        });
    },
    async onSubmitForm(event) {
      event.preventDefault();

      // Валидация
      if (!this.form.firstName.value) {
        this.form.firstName.state = false;
        this.form.firstName.invalidFeedback = 'Обязательное поле!';
        return;
      }
      if (!this.form.lastName.value) {
        this.form.lastName.state = false;
        this.form.lastName.invalidFeedback = 'Обязательное поле!';
        return;
      }

      // Отправка на сервер
      this.isLoading = true;
      try {
        await changePersonalData(
          this.form.firstName.value,
          this.form.lastName.value,
          this.form.isProfessionalUser.value,
          this.form.country.value,
          this.form.city.value,
        );
        this.isLoading = false;

        // Уведомление об успешности
        this.$bvToast.toast('Успешно!', {
          title: 'Изменение персональных данных',
          variant: 'success',
          autoHideDelay: 5000,
        });
      } catch (e) {
        this.isLoading = false;
        this.serverErrorMessage = await getErrorTextFromErResp(e);
      }
    },
    async loadForm() {
      try {
        const resp = await getPersonalData();
        this.form.firstName.value = resp.firstName;
        this.form.lastName.value = resp.lastName;
        this.form.country.value = resp.country;
        this.form.city.value = resp.city;
        this.form.isProfessionalUser.value = resp.isProfessionalUser;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.serverErrorMessage = e;
      }
    },
    async getCitiesList(value) {
      this.listCity = await getListCity(value);
    },
  },

  async mounted() {
    this.serverErrorMessage = null;
    this.isLoading = true;
    await this.loadForm();

    // Берем из JWT данные профиля
    try {
      const jwt = this.$store.getters['auth/jwt'];
      this.profile = await jsonwebtoken.verify(jwt, config.publicKeyForJwt);
    } catch (err) {
      this.profile = null;
    }
  },
};
</script>

<style lang="scss" scoped>
.profile-view {
  margin: 0 auto;
  padding: 25px 10px 50px 10px;
  text-align: left;

  .settings-column {
    width: 100%;

    @media (min-width: 992px) {
      width: 48%;
    }
  }
}
</style>
